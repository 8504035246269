

interface IPagination {
  page: number;
  total: number;
  rowsPerPage: number;
}

import {defineComponent, getCurrentInstance, ref, watch} from "vue";

export default defineComponent({
  name: "Pagination",
  props: {
    total: {required: false, default: 0},
    itemPerPage: {required: false, default: 10},
    currentPage: {required: false, default: 1}
  },
  emits: ['current-change'],
  setup(props, {emit}) {
    console.log(props);
    const pagination = ref<IPagination>({
      page: props.currentPage,
      total: props.total,
      rowsPerPage: props.itemPerPage,
    });
    watch(() => props.total, (cb) => {
      pagination.value.total = cb;
    })

    watch(() => props.currentPage, (cb) => {
      pagination.value.page = cb;
    })

    const vnodeProps = getCurrentInstance()?.vnode.props || {};
    const currentPageChange = (val) => {
      console.log(val)
      console.log(vnodeProps)
      if ("onCurrentChange" in vnodeProps) {
        emit("current-change", val);
      } else {
        pagination.value.page = val;
      }
    };
    return {
      pagination,
      currentPageChange
    }
  }
})
