
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import {defineComponent, ref, watch} from 'vue'

export default defineComponent({
  name: "DateTimeSelect",
  components: {VueDatePicker},
  props: {
    name: {required: false, default: 'DateTimeSelect'},
    modelValue: {
      required: true,
      default: new Date().getTime()
    },
    textInput: {required: false, default: true},
    clazz: {required: false, default: 'form-control form-control-sm'}
  },
  setup(props) {

    const selected = ref<any>(props.modelValue !== null ? new Date(props.modelValue) : '');
    watch(() => props.modelValue, (cb) => {
      selected.value = cb !== null ? new Date(cb) : '';
    })
    return {
      selected,
    }
  },
  emit: ['update:modelValue', 'selected'],
  methods: {

    onSelect(e) {
      this.selected = e;
      this.$emit('update:modelValue', e?.getTime())
      this.$emit('selected', e?.getTime())
    },
    onInput() {
      this.$emit('update:modelValue', this?.selected?.getTime())
    }
  }
})
