export interface IAddress {
  street: string;
  street2: string;
  zip: string;
  countryCode: string;
  countryName: string;
  stateCode: string;
  stateName: string;
  cityName: string
}

export interface IAddressE {
  line1: string;
  line2: string;
  zip: string;
  countryCode: string;
  countryName: string;
  stateCode: string;
  stateName: string;
  cityName: string
}


export class ModelAddress implements IAddress {
  cityName = '';
  countryCode = '';
  countryName = '';
  street = '';
  street2 = '';
  stateCode = '';
  stateName = '';
  zip = '';
}

